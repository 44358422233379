import styles from "../pages/styles/SidebarFeaturesPage.module.scss";
import classNames from "classnames";

export default function NewTag(props: {className?: string}) {
    const newTagClassName = classNames({
        [styles.tag]: true,
        [props.className || '']: props.className,
    });

    return <span className={newTagClassName}>NEW</span>
}
