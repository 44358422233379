import WatsonApi from "../backends/WatsonApi";
import {getTerraceTypeFromContentItem} from "../components/schoolfeed/PostManager/SchoolFeedPostManager";
import {runInAction, values} from "mobx";
import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";
import {IView} from "../stores/InterfaceStore";
import {ReactiveContentItem} from "../stores/SchoolFeedStore";

export function useCreateContentItem(handleAddSchoolFeedContentItem?) {
    const {gridStore, interfaceStore} = useContext(StoreContext);

    async function handleSave (contentItem, organization, post_to_facebook: boolean) {
        const isSchoolFeed = interfaceStore.view === IView.SCHOOLFEED_PAGE;
        const client = await WatsonApi();
        const result = await client.apis.organizations.organizations_content_items_create({
            "organization_pk": organization.id,
            "post_to_facebook": post_to_facebook,
            data: contentItem,
        })
        const item = JSON.parse(result.data);

        if (isSchoolFeed && handleAddSchoolFeedContentItem) {
            const newItem = new ReactiveContentItem({...item, organization})
            handleAddSchoolFeedContentItem(newItem);
        } else {
            runInAction(() => {
                const terraceType = getTerraceTypeFromContentItem(contentItem);
                const terrace = values(gridStore.blocks).find(b => b.type === terraceType);
                if (terrace) {
                    // expanded organization is not returned from an update, and it might have changed
                    const newItem = new ReactiveContentItem({...item, organization})
                    terrace.content_items.unshift(newItem);
                }
            })
        }
    }

    return { handleSave };
}
