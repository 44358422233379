import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from "./styles/_SidebarPage.module.scss";
import { observer } from 'mobx-react';

const headerTextClassName = classNames({
    [styles.headerText]: true,
});

const SidebarPage = observer(props => {
    const lightThemePageClassName = classNames({
        [styles.lightThemePage]: true,
        [props.className]: props.className,
    });

    return (
        <Fragment>
            <div className={headerTextClassName}>
                {props.thumbnail || ""}
                <h2>{props.title}</h2>
                {props.subtitle && <h3>{props.subtitle}</h3>}
            </div>
            <article className={lightThemePageClassName}>
                {props.children}
            </article>
        </Fragment>
    );
});

SidebarPage.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    className: PropTypes.string,
};
SidebarPage.defaultProps = {
    title: "Page Title",
    subtitle: "",
    className: "",
}

export default SidebarPage;
